import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import { ChallengesPlatform } from './editor/ChallengesPlatform';

import AppManifest, {
  AppManagerEvent,
  PageActionsEvent,
} from './editor/types/manifest';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import {
  EditorSDK,
  PlatformEvent,
  EditorReadyOptions,
  EventType,
} from '@wix/platform-editor-sdk';

let platform: ChallengesPlatform;

const editorApi = {
  editorReady: async (
    editorSDK: EditorSDK,
    appDefinitionId: string,
    options: EditorReadyOptions,
    flowAPI: EditorScriptFlowAPI,
  ): Promise<void> => {
    const { firstInstall, origin } = options;
    const isADI = origin?.type?.toLowerCase().includes('adi');
    const isEditorWizardCreationFlow =
      options.origin?.info?.type === 'SILENT_INSTALL_SITE_CREATION' ||
      options.origin?.info?.type === 'SILENT_INSTALL';

    platform = new ChallengesPlatform(
      editorSDK,
      appDefinitionId,
      flowAPI,
      isADI,
      firstInstall,
      isEditorWizardCreationFlow,
    );

    const isChallengesInstalled = await platform.isChallengesInstalled();
    if (!isChallengesInstalled) {
      return;
    }

    /*
      If `firstInstall`, the installation process will wait until this function will be finished.
      So any bugs or slow scripts will be reflected on installation rates and kpis. It should be as simple as possible.
      Without `firstInstall` this script will be just run on every editor load.
     */
    const editorReadyBaseTransaction = async () => {
      await platform.setupProgramsPages(firstInstall);
      await platform.participantPageMigrations();
    };

    await editorSDK.document.transactions.runAndWaitForApproval(
      appDefinitionId,
      editorReadyBaseTransaction,
    );
  },

  getAppManifest: async ({ appManifestBuilder }): Promise<AppManifest> => {
    return platform.getManifest({ appManifestBuilder });
  },

  onEvent: async (event: PlatformEvent): Promise<void> => {
    switch (event.eventType) {
      case PageActionsEvent.REMOVE as any: // page_remove
        await platform.deleteApp(event.eventPayload);
        break;
      case EventType.appActionClicked:
      case AppManagerEvent.OPEN_DASHBOARD as any: // open_dashboard
        await platform.openDashboard(
          event?.eventPayload?.actionId || AppManagerEvent.OPEN_DASHBOARD,
        );
        break;
      default:
        return;
      // do nothing
    }
    return;
  },

  handleAction: async (event) => {
    const { type, payload } = event;
    const isOPAppDefId = payload.appDefinitionId === platform?.appDefId;

    if (!platform?.appDefId) {
      return;
    }

    if (type === 'appInstalled') {
      if (isOPAppDefId) {
        await platform.installDependencies();
        await platform.updatePublicData();
      }
    } else if (type === 'migrate') {
      await platform.handleMigration(payload);
    }
  },
};

const editorApiWithMA = withMembersArea(editorApi, {
  disableADI: false,
  installAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.MY_PROGRAMS,
    MA_APP_IDS.ABOUT,
  ],
});

export const editorReady =
  editorApiWithMA.editorReady as typeof editorApi.editorReady;
export const getAppManifest =
  editorApiWithMA.getAppManifest as typeof editorApi.getAppManifest;
export const onEvent = editorApiWithMA.onEvent as typeof editorApi.onEvent;
export const handleAction = editorApiWithMA.handleAction;
